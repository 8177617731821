<template>
  <div class="h-full min-h-[100vh] relative" :style="style">
    <div class="pt-16 md:pt-24">
      <Card class="p-8 max-w-5xl mx-auto text-md lg:text-lg flex flex-wrap bg-white">
        <BioTexts class="w-full sm:w-1/2 md:w-2/3 lg:w-3/4 order-2 sm:order-1 mt-6 sm:m-0" />

        <img class="block order-1 sm:order-2 mx-auto md:m-0 max-w-[400px] max-h-[max(30vh,30vw)] lg:max-h-[30vh]" src="../assets/img/bio.jpg" />

        <p class="font-citation italic tracking-wider w-full order-3 p-4 md:p-8 pb-0 text-xl md:text-lg lg:text-xl text-center">
          <span class="font-bold text-2xl lg:text-3xl">‟</span>
          <span class="pl-3 pr-2">Le corps et l'esprit ont besoin d'harmonie pour vivre ensemble !</span>
          <span class="font-bold text-2xl lg:text-3xl">”</span>
        </p>
      </Card>
    </div>
  </div>
</template>
<script>
import BioTexts from '@/components/BioTexts';
import Card from '@/components/Card';

export default {
  components: { BioTexts, Card },
  computed: {
    style() {
      return {
        background: `url(${require('../assets/img/bg-poussan.jpg')}) no-repeat`,
        backgroundSize: 'cover'
      };
    }
  }
};
</script>
