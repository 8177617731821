<template>
  <div>
    <p class="p-4">Masseur Kinésithérapeute depuis 35 ans, le contact par le toucher a toujours tenu une place privilégiée dans mon exercice professionnel.</p>
    <p class="p-4">Pendant 12 années au sein d'un service de personnes polyhandicapées, la recherche du confort du patient était ma priorité absolue.</p>
    <p class="p-4">Le massage me permet donc d'être toujours à l'écoute et de procurer de façon bienveillante, ce Bien-Être tant attendu.</p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
